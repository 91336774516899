import Button from 'components/Button';
import ReactLoading from 'react-loading';
import Container from 'components/Container';
import Divider from 'components/Divider';
import { Col, ColPercent, Row } from 'components/Grid';
import Input, { InputRow, InputWrapper } from 'components/Input';
import InputSelect from 'components/InputSelect';
import AttachChildModal from 'components/Modals/AttachChildModal';
import Spacer from 'components/Spacer';
import PhotoUpdate from 'components/PhotoUpdate';
import { uf, mxStates } from '../constants';
import { Form, Formik } from 'formik';
import useRegionalization from 'hooks/useRegionalization';
import { Fragment, useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
   AppointmentsData,
   DonationData,
   DonorData,
   donorsDetails,
} from 'services/slices/donorsDetails';
import { zipcodeSearch } from 'services/slices/zipcodeSearch';
import { RootState } from 'services/store';
import {
   CepRegex,
   INVALID_FORMAT,
   INVALID_LEGALDATE,
   REQUIRED_LABEL,
} from 'services/validators';
import theme from '../styles/theme';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router';
import { ConfirmationApproveOrReproveDonor } from 'components/Modals/ConfirmationApproveOrReproveDonor';
import { updateDonor, updateDonorDetails } from 'services/slices/updateDonor';
import { deleteDonor } from 'services/slices/deleteParnert';
import { getPhoneCode, CountryCode, parse } from 'libphonenumber-js';
import routes from 'routes/routes.json';
import PhoneInput from './../components/PhoneInput';
import 'yup-phone';
import { approvePhoto } from 'services/slices/approvePhoto';
import calculateAge from 'utils/ageCalculator';
import BadgeStatus from 'components/BadgeStatus';
import { Modal } from 'react-bootstrap';
import FileUploader from 'components/PhotoUpdate';

type Values = {
   name: string;
   document: string;
   email: string;
   phone: string;
   ocupation: string;
   birthdate: string;
   gender: string;
   address_street: string;
   address_number: string;
   address_complement?: string;
   address_zipcode: string;
   address_neightborhood: string;
   address_city: string;
   address_state: string;
   event_status: string;
};

type StateProps = {
   uf: string;
};
type ButtonApprovationProps = {
   gotChecked: boolean;
};

const DonorDetails = (): JSX.Element => {
   const history = useHistory();
   const { country, regionalization, phoneCountry, setPhoneCountry } =
      useRegionalization();
   const dispatch = useDispatch();
   const t = useFormatMessage();
   const { donorID } = useParams<{ donorID: string }>();
   const [districts] = useState<StateProps[]>(country === 'mx' ? mxStates : uf);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [inputDisable, setInputDisable] = useState<boolean>(true);
   const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
   const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
   const [modalOpen, setModalOpen] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<string>('');
   const [selectedCountry, setSelectedCountry] = useState<string>(country);
   const [isValidating, setIsValidating] = useState<boolean>(false);
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [rowToDelete, setRowToDelete] = useState<number | null>(null);
   const [imageBase64, setImageBase64] = useState<string>('');

   const {
      result,
      loadingStatus,
      address,
      loadingStatusAddress,
      appointments,
      donationData,
      donorData,
      photoApproveStatus,
   } = useSelector((state: RootState) => ({
      appointments: state.donorsDetail.appointments,
      result: state.donorsDetail.donor_data,
      loadingStatus: state.donorsDetail.status,
      address: state.zipcodeSearch.address,
      loadingStatusAddress: state.zipcodeSearch.status,
      donationData: state.donorsDetail.donation_data,
      donorData: state.donorsDetail.donor_data,
      photoApproveStatus: state.approvePhoto.status,
   }));

   const approvalUserPhoto = ({ image_id, status }) => {
      dispatch(approvePhoto({ image_id, status }));
   };

   const handleFileLoad = (base64String: string) => {
      setImageBase64(base64String);
   };

   useEffect(() => {
      dispatch(donorsDetails({ id: donorID }));
   }, []);

   useEffect(() => {
      if (loadingStatus === 'ok') {
         const numberString = `+${result.phone.country}${result.phone.value}`;
         // const donorCountry = parse(numberString).country.toLowerCase();
         setSelectedCountry('CO');
      }
   }, [loadingStatus]);

   useEffect(() => {
      setLoadingAddress(loadingStatusAddress === 'loading');
   }, [loadingStatusAddress]);

   useEffect(() => {
      if (photoApproveStatus === 'ok' && actionSelected !== '') {
         if (actionSelected === 'approved') {
            toast.success(t({ id: 'Foto Aprovada' }));
         } else {
            toast.success(t({ id: 'Foto Reprovada' }));
         }

         setActionSelected('');
         setIsValidating(false);

         dispatch(donorsDetails({ id: donorID }));
      }

      if (photoApproveStatus === 'rejected' && actionSelected !== '') {
         if (actionSelected === 'approved') {
            toast.warning(t({ id: 'Nao foi possivel aprovar a foto' }));
         } else {
            toast.warning(t({ id: 'Nao foi possivel reprovar a foto' }));
         }
         setIsValidating(false);
         setActionSelected('');
      }
   }, [photoApproveStatus]);

   const handleCreateAppointment = () => {
      setOpenModal(false);
      toast.success(t({ id: 'Compromisso vinculado com sucesso' }));
      dispatch(donorsDetails({ id: donorID }));
   };

   const downLoadPhotoDonor = (url) => {
      const enlace = document.createElement('a');
      enlace.href = url;

      const nombreArchivo = url.substring(url.lastIndexOf('/') + 1);
      enlace.download = nombreArchivo;

      enlace.click();
   };

   const handleApproval = async (donor: DonorData, approve: boolean) => {
      setIsValidating(true);
      setActionSelected(approve ? 'approved' : 'reproved');
      const donorImage = donor.id;

      await approvalUserPhoto({
         image_id: [donorImage],
         status: approve ? 'approved' : 'reproved',
      });
   };

   const handleOpenModal = (status: string) => {
      setActionSelected(status);
      setModalOpen(true);
   };

   const handleSubmit = async (values: Values) => {
      setIsSubmiting(true);

      const obj: updateDonorDetails = {
         ...values,
         phone: {
            country: getPhoneCode(phoneCountry).toString(),
            value: values.phone,
         },
         address_zipcode: values.address_zipcode.replace('-', ''),
         donor_photo_base64: imageBase64,
      };

      delete obj.name;
      delete obj.address_complement;
      delete obj.address_neightborhood;
      delete obj.address_number;
      delete obj.address_state;
      delete obj.address_zipcode;
      delete obj.event_status;
      delete obj.ocupation;

      const response: any = await dispatch(
         updateDonor({ id: donorID, data: obj }),
      );

      if (updateDonor.fulfilled.match(response)) {
         toast.success(t({ id: 'Dados atualizados' }).toString());
      } else {
         toast.error(t({ id: 'Algo deu errado' }).toString());
      }

      setIsSubmiting(false);
      setInputDisable(true);
   };

   const handleEdit = () => {
      setInputDisable(!inputDisable);

      if (inputDisable === true) {
         toast.success(
            t({
               id: 'Campos permitidos para edicao',
            }).toString(),
         );
      }
   };

   const handleDeleteClick = (rowIndex: number) => {
      setRowToDelete(rowIndex);
      setShowConfirmModal(true);
   };

   const handleConfirmDelete = async () => {
      const response: any = await dispatch(deleteDonor({ id: donorID }));

      if (deleteDonor.fulfilled.match(response)) {
         toast.success(t({ id: 'Eliminado exitosamente' }).toString());
      } else {
         toast.error(t({ id: 'Error al eliminar el registro' }).toString());
      }

      setShowConfirmModal(false);
      history.push(routes.approvals);
   };

   const handleConfirmClose = () => {
      setShowConfirmModal(false);
   };

   const FormSchema = yup.object().shape({
      name: yup.string().required(REQUIRED_LABEL),
      document: yup.string().required(REQUIRED_LABEL),
      email: yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
      phone: yup
         .string()
         .phone(phoneCountry, true, INVALID_FORMAT)
         .required(REQUIRED_LABEL),
      birthdate: yup
         .string()
         .required(REQUIRED_LABEL)
         .test('DOB', INVALID_LEGALDATE, (value) => {
            if (value) {
               const newDate = value.split('-').reverse().join('/');

               const age = calculateAge(newDate);

               return age >= 18 && age <= 120;
            }
         }),
      ocupation: yup.string().required(REQUIRED_LABEL),
      gender: yup.string().required(REQUIRED_LABEL),
      address_zipcode: yup
         .string()
         .required(REQUIRED_LABEL)
         .matches(CepRegex, INVALID_FORMAT),
      address_street: yup.string().required(REQUIRED_LABEL),
      address_number: yup.string().required(REQUIRED_LABEL),
      address_complement: yup.string(),
      address_neightborhood: yup.string().required(REQUIRED_LABEL),
      address_city: yup.string().required(REQUIRED_LABEL),
      address_state: yup.string().required(REQUIRED_LABEL),
   });

   return (
      <>
         {loadingStatus === 'loading' && (
            <LoadingContainer>
               <ReactLoading
                  type="spin"
                  color={theme.colors.brand.primary.main}
               />
            </LoadingContainer>
         )}
         {loadingStatus === 'ok' && (
            <Fragment>
               <Formik
                  initialValues={{
                     name: result?.name ?? '',
                     document: result?.document ?? '',
                     email: result?.email ?? '',
                     phone: result.phone.value.toString() ?? '',
                     birthdate: result?.birthdate ?? '',
                     ocupation: result?.ocupation ?? '',
                     gender: result?.gender ?? '',
                     address_zipcode: result?.address_zipcode
                        ? result.address_zipcode.toString()
                        : '',
                     address_street: result?.address_street ?? '',
                     address_number: result?.address_number
                        ? result?.address_number.toString()
                        : '',
                     address_complement: result?.address_complement ?? '',
                     address_neightborhood: result?.address_neightborhood ?? '',
                     address_city: result?.address_city ?? '',
                     address_state: result?.address_state ?? '',
                     event_status: '',
                  }}
                  onSubmit={handleSubmit}
                  // validationSchema={FormSchema}
               >
                  {({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     setFieldValue,
                  }) => {
                     const handleSearchZipcode = async (value: string) => {
                        const zipcode = value.replace('-', '');

                        if (zipcode.length === 8) {
                           await dispatch(zipcodeSearch({ zipcode }));
                        }
                     };

                     useEffect(() => {
                        if (loadingStatusAddress === 'ok') {
                           setFieldValue('address_city', address.address_city);
                           setFieldValue(
                              'address_neightborhood',
                              address.address_neightborhood,
                           );
                           setFieldValue(
                              'address_state',
                              address.address_state,
                           );
                           setFieldValue(
                              'address_street',
                              address.address_street,
                           );
                        }
                        if (loadingStatusAddress === 'rejected') {
                           toast.error(
                              t({ id: 'CEP nao encontrado' }).toString(),
                              {
                                 hideProgressBar: false,
                                 autoClose: 3000,
                                 pauseOnFocusLoss: false,
                              },
                           );
                        }
                     }, [loadingStatusAddress]);

                     return (
                        <Container>
                           <Form onSubmit={handleSubmit}>
                              <Spacer top={32} />
                              <Breadcrumb>
                                 <BreadcrumbBackButton
                                    onClick={() => history.goBack()}
                                 >
                                    <img
                                       src={`${theme.pathIcons}/arrowLeft.svg`}
                                    />
                                    {t({ id: 'Voltar' })}
                                 </BreadcrumbBackButton>
                                 <Spacer right={40} />

                                 <BreadcrumbTitle>
                                    {result.name}
                                 </BreadcrumbTitle>

                                 {country === 'mx' ? (
                                    <Item>
                                       <ItemTitle>
                                          {t({ id: 'Pagamento_State' })}
                                       </ItemTitle>
                                       <Spacer bottom={16} />
                                       <ItemDescription>
                                          <BadgeStatus
                                             status={
                                                donationData.donation_status
                                             }
                                          />

                                          {t({
                                             id: donationData.donation_status,
                                          })}
                                       </ItemDescription>
                                    </Item>
                                 ) : null}

                                 <Item>
                                    <ItemTitle>
                                       {t({
                                          id: 'Compromissos',
                                       })}
                                    </ItemTitle>
                                    <Spacer bottom={16} />
                                    <ItemDescription>
                                       <i className="far fa-user" />
                                       <Spacer right={18} />
                                       {appointments.length === 0 ? (
                                          <h1>
                                             {t({
                                                id: 'Por enquanto nenhum',
                                             })}
                                          </h1>
                                       ) : (
                                          appointments.length
                                       )}
                                    </ItemDescription>
                                 </Item>

                                 {donorData.simma_donor_id !== null && (
                                    <Item>
                                       <ItemTitle>Partner ID</ItemTitle>
                                       <Spacer bottom={16} />
                                       <ItemDescription>
                                          {donorData?.simma_donor_id ?? '-'}
                                       </ItemDescription>
                                    </Item>
                                 )}
                              </Breadcrumb>

                              <Spacer bottom={41} />

                              <Row alignItems="flex-start">
                                 <Col xs={ColPercent(12)} lg={ColPercent(8)}>
                                    <Row>
                                       {donationData && (
                                          <Col xs={ColPercent(12)}>
                                             <SectionHeaderContainer>
                                                <SectionHeaderTitle>
                                                   {t({
                                                      id: 'Compromissos do Doador',
                                                   })}
                                                </SectionHeaderTitle>
                                                <SectionHeaderButton
                                                   type="button"
                                                   onClick={() => {
                                                      setOpenModal(true);
                                                   }}
                                                >
                                                   {t({
                                                      id: 'Adicionar compromisso',
                                                   })}
                                                   <Spacer right={8} />
                                                   <i className="fas fa-plus" />
                                                </SectionHeaderButton>
                                             </SectionHeaderContainer>

                                             <Spacer bottom={33} />

                                             <CommitmentContainer
                                                empty={
                                                   appointments.length === 0
                                                }
                                             >
                                                {appointments.length > 0 ? (
                                                   <Fragment>
                                                      {appointments.map(
                                                         (
                                                            items: AppointmentsData,
                                                         ) => (
                                                            <CommitmentKid>
                                                               <CommitmentPhotoWrapper>
                                                                  <CommitmentPhoto
                                                                     src={`https://chosenapi.worldvision.co/image/${items?.child_photo}`}
                                                                  />
                                                                  <CommitmentPhotoOverlay
                                                                     onClick={() =>
                                                                        history.push(
                                                                           `${routes.chosenChild.replace(
                                                                              ':childID',
                                                                              items.child_id,
                                                                           )}`,
                                                                        )
                                                                     }
                                                                  >
                                                                     <i className="fas fa-pen" />
                                                                  </CommitmentPhotoOverlay>
                                                               </CommitmentPhotoWrapper>
                                                               <Spacer
                                                                  bottom={24}
                                                               />
                                                               <CommitmentName>
                                                                  {
                                                                     items.child_name
                                                                  }
                                                               </CommitmentName>
                                                            </CommitmentKid>
                                                         ),
                                                      )}
                                                   </Fragment>
                                                ) : (
                                                   <Fragment>
                                                      <CommitmentMessage>
                                                         {t({
                                                            id: 'Esse doador nao possui nenhum compromisso vinculado ate o momento',
                                                         })}
                                                      </CommitmentMessage>

                                                      <Spacer bottom={24} />

                                                      <CommitmentButton
                                                         type="button"
                                                         onClick={() =>
                                                            setOpenModal(true)
                                                         }
                                                      >
                                                         {t({
                                                            id: 'Adicionar',
                                                         })}
                                                      </CommitmentButton>
                                                   </Fragment>
                                                )}
                                             </CommitmentContainer>

                                             <Spacer bottom={40} />

                                             <Divider />

                                             <Spacer bottom={33} />
                                          </Col>
                                       )}

                                       <Col xs={ColPercent(12)}>
                                          <SectionHeaderContainer>
                                             <SectionHeaderTitle>
                                                {t({
                                                   id: 'Informacoes do Doador',
                                                })}
                                             </SectionHeaderTitle>
                                             <SectionHeaderButton
                                                type="button"
                                                onClick={handleEdit}
                                             >
                                                {t({ id: 'Editar' })}
                                                <Spacer right={8} />
                                                <i className="fas fa-pen" />
                                             </SectionHeaderButton>
                                          </SectionHeaderContainer>

                                          <Spacer bottom={33} />
                                          <InputRow>
                                             <InputWrapper>
                                                <Input
                                                   disabled
                                                   label={t({
                                                      id: 'Nome completo',
                                                   }).toString()}
                                                   placeholder={t({
                                                      id: 'Digite',
                                                   }).toString()}
                                                   value={values.name}
                                                   onChange={handleChange(
                                                      'name',
                                                   )}
                                                   onBlur={handleBlur('name')}
                                                   error={
                                                      touched.name &&
                                                      errors.name
                                                         ? t({
                                                              id: errors.name,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   type="text"
                                                   name="name"
                                                   autoComplete="name"
                                                   autoCapitalize="words"
                                                />
                                                <Spacer bottom={24} />
                                             </InputWrapper>

                                             <Spacer right={40} />

                                             <InputWrapper>
                                                <Input
                                                   disabled={inputDisable}
                                                   label={t({
                                                      id: 'Email',
                                                   }).toString()}
                                                   placeholder={t({
                                                      id: 'Digite',
                                                   }).toString()}
                                                   value={values.email}
                                                   onChange={handleChange(
                                                      'email',
                                                   )}
                                                   onBlur={handleBlur('email')}
                                                   error={
                                                      touched.email &&
                                                      errors.email
                                                         ? t({
                                                              id: errors.email,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   type="text"
                                                   name="email"
                                                   autoComplete="email"
                                                   autoCapitalize="off"
                                                />

                                                <Spacer bottom={24} />
                                             </InputWrapper>
                                          </InputRow>
                                          <InputRow>
                                             <InputWrapper>
                                                <PhoneInput
                                                   disabled={inputDisable}
                                                   label={t({
                                                      id: 'Telefone',
                                                   }).toString()}
                                                   placeholder={t({
                                                      id: 'Digite',
                                                   }).toString()}
                                                   value={values.phone}
                                                   onChange={(e: any) => {
                                                      handleChange('phone')(
                                                         e.target.rawValue,
                                                      );
                                                   }}
                                                   onBlur={handleBlur('phone')}
                                                   error={
                                                      touched.phone &&
                                                      errors.phone
                                                         ? t({
                                                              id: errors.phone,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   type="tel"
                                                   name="phone"
                                                   autoComplete="tel"
                                                   autoCapitalize="off"
                                                   maxLength={13}
                                                   initialCountry={
                                                      selectedCountry
                                                   }
                                                   onCountryChanged={(
                                                      phoneCountry: CountryCode,
                                                   ) => {
                                                      handleChange('phone')('');
                                                      setPhoneCountry(
                                                         phoneCountry,
                                                      );
                                                   }}
                                                />
                                                <Spacer bottom={24} />
                                             </InputWrapper>

                                             <Spacer right={40} />
                                             <InputWrapper>
                                                <Input
                                                   disabled={inputDisable}
                                                   label={t({
                                                      id: 'Nascimento',
                                                   }).toString()}
                                                   placeholder={t({
                                                      id: 'Digite',
                                                   }).toString()}
                                                   value={values.birthdate}
                                                   onChange={handleChange(
                                                      'birthdate',
                                                   )}
                                                   onBlur={handleBlur(
                                                      'birthdate',
                                                   )}
                                                   error={
                                                      touched.birthdate &&
                                                      errors.birthdate
                                                         ? t({
                                                              id: errors.birthdate,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   type="date"
                                                   name="birthdate"
                                                   autoComplete="bday"
                                                />
                                                <Spacer bottom={24} />
                                             </InputWrapper>
                                          </InputRow>
                                          <InputRow>
                                             <InputWrapper>
                                                <Input
                                                   disabled={inputDisable}
                                                   label={t({
                                                      id: 'CPF',
                                                   }).toString()}
                                                   placeholder={
                                                      country === 'mx'
                                                         ? t({
                                                              id: 'Digite',
                                                           }).toString()
                                                         : '000.000.000-00'
                                                   }
                                                   value={values.document}
                                                   onChange={handleChange(
                                                      'document',
                                                   )}
                                                   onBlur={(e) => {
                                                      handleSearchZipcode(
                                                         e.target.value,
                                                      );
                                                      handleBlur(e);
                                                   }}
                                                   error={
                                                      touched.document &&
                                                      errors.document
                                                         ? t({
                                                              id: errors.document,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   type="text"
                                                   name="document"
                                                   autoCapitalize="off"
                                                />
                                                <Spacer bottom={24} />
                                             </InputWrapper>

                                             <Spacer right={40} />

                                             <InputWrapper>
                                                <InputSelect
                                                   disabled={inputDisable}
                                                   label={t({
                                                      id: 'Sexo',
                                                   }).toString()}
                                                   placeholder={t({
                                                      id: 'Digite',
                                                   }).toString()}
                                                   value={values.gender}
                                                   onChange={handleChange(
                                                      'gender',
                                                   )}
                                                   onBlur={handleBlur('gender')}
                                                   error={
                                                      touched.gender &&
                                                      errors.gender
                                                         ? t({
                                                              id: errors.gender,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   name="gender"
                                                   autoComplete="off"
                                                   autoCapitalize="off"
                                                >
                                                   <option>
                                                      {t({
                                                         id: 'Selecione o genero',
                                                      }).toString()}
                                                   </option>
                                                   <option value="Masculino">
                                                      {t({ id: 'Masculino' })}
                                                   </option>
                                                   <option value="Feminino">
                                                      {t({ id: 'Feminino' })}
                                                   </option>
                                                </InputSelect>
                                                <Spacer bottom={24} />
                                             </InputWrapper>
                                          </InputRow>

                                          <InputRow>
                                             <InputWrapper>
                                                <Input
                                                   disabled={
                                                      inputDisable ||
                                                      loadingAddress
                                                   }
                                                   label={t({
                                                      id: 'Cidade',
                                                   }).toString()}
                                                   placeholder={
                                                      loadingAddress
                                                         ? 'buscando cidade...'
                                                         : t({
                                                              id: 'Digite',
                                                           }).toString()
                                                   }
                                                   value={values.address_city}
                                                   onChange={handleChange(
                                                      'address_city',
                                                   )}
                                                   onBlur={handleBlur(
                                                      'address_city',
                                                   )}
                                                   error={
                                                      touched.address_city &&
                                                      errors.address_city
                                                         ? t({
                                                              id: errors.address_city,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   type="tel"
                                                   name="address_city"
                                                   autoComplete="off"
                                                   autoCapitalize="off"
                                                />
                                                <Spacer bottom={24} />
                                             </InputWrapper>
                                             <Spacer right={40} />
                                             <InputWrapper
                                                style={{ maxWidth: '80%' }}
                                             >
                                                <Input
                                                   disabled={
                                                      inputDisable ||
                                                      loadingAddress
                                                   }
                                                   label={t({
                                                      id: 'Rua',
                                                   }).toString()}
                                                   placeholder={
                                                      loadingAddress
                                                         ? 'buscando rua...'
                                                         : t({
                                                              id: 'Digite',
                                                           }).toString()
                                                   }
                                                   value={values.address_street}
                                                   onChange={handleChange(
                                                      'address_street',
                                                   )}
                                                   onBlur={handleBlur(
                                                      'address_street',
                                                   )}
                                                   error={
                                                      touched.address_street &&
                                                      errors.address_street
                                                         ? t({
                                                              id: errors.address_street,
                                                           }).toString()
                                                         : undefined
                                                   }
                                                   type="tel"
                                                   name="address_street"
                                                   autoComplete="off"
                                                   autoCapitalize="off"
                                                />
                                                <Spacer bottom={24} />
                                             </InputWrapper>
                                          </InputRow>
                                          <Spacer bottom={16} />

                                          <Divider />

                                          <Spacer bottom={40} />

                                          <Button
                                             type="submit"
                                             label={t({
                                                id: 'Salvar alterações',
                                             }).toString()}
                                             disabled={
                                                inputDisable || isSubmiting
                                             }
                                          />

                                          <Spacer bottom={40} />
                                       </Col>
                                    </Row>
                                 </Col>

                                 <Col xs={ColPercent(12)} lg={ColPercent(4)}>
                                    <SectionHeaderContainer>
                                       <SectionHeaderTitle>
                                          {t({ id: 'Foto do Doador' })}
                                       </SectionHeaderTitle>

                                       <PhotoApproveButtons>
                                          <ButtonApproved
                                             type="button"
                                             disabled={
                                                result.validation_status !==
                                                   'pending' || isValidating
                                             }
                                             gotChecked={
                                                result.validation_status ===
                                                'approved'
                                             }
                                             onClick={() => {
                                                handleOpenModal('approved');
                                             }}
                                          >
                                             <i className="fas fa-check"></i>
                                          </ButtonApproved>

                                          <ButtonDisapproved
                                             type="button"
                                             disabled={
                                                result.validation_status !==
                                                   'pending' || isValidating
                                             }
                                             gotChecked={
                                                result.validation_status ===
                                                'reproved'
                                             }
                                             onClick={() => {
                                                handleOpenModal('reproved');
                                             }}
                                          >
                                             <i className="fas fa-times"></i>
                                          </ButtonDisapproved>
                                       </PhotoApproveButtons>
                                    </SectionHeaderContainer>

                                    <Spacer bottom={33} />

                                    {result.donor_photo_base64 ? (
                                       <DonorPhoto
                                          src={`https://chosenapi.worldvision.co/image${result.donor_photo_base64}`}
                                       />
                                    ) : (
                                       <DonorPhoto
                                          src={
                                             imageBase64
                                                ? imageBase64
                                                : `https://chosenapi.worldvision.co/image/static/images/sinImagen.png`
                                          }
                                       />
                                    )}

                                    <Spacer bottom={40} />

                                    <ContainerPhotoOption>
                                       {result.donor_photo_base64 ? (
                                          <CommitmentButton
                                             type="button"
                                             onClick={() =>
                                                downLoadPhotoDonor(
                                                   `https://chosenapi.worldvision.co/image${result.donor_photo_base64}`,
                                                )
                                             }
                                          >
                                             {t({
                                                id: 'Open foto',
                                             })}
                                          </CommitmentButton>
                                       ) : (
                                          <CommitmentButton
                                             type="button"
                                             disabled={true}
                                          >
                                             {t({
                                                id: 'Open foto',
                                             })}
                                          </CommitmentButton>
                                       )}

                                       <FileUploader
                                          onFileLoad={handleFileLoad}
                                       />
                                       <CommitmentButtonUpdate
                                          type="button"
                                          onClick={() =>
                                             document
                                                .getElementById('fileInput')
                                                ?.click()
                                          }
                                          disabled={inputDisable || isSubmiting}
                                       >
                                          Cargar foto
                                       </CommitmentButtonUpdate>
                                    </ContainerPhotoOption>
                                    <Spacer bottom={40} />

                                    <DonorSince>
                                       {t({ id: 'Cadastrado desde ' })}
                                       {`${new Date(
                                          result.created_at,
                                       ).toLocaleDateString()}`}
                                    </DonorSince>
                                 </Col>
                                 <ContenDelet>
                                    <Button
                                       type="button"
                                       onClick={() => handleDeleteClick(1)}
                                       label="Eliminar Registro"
                                    />
                                 </ContenDelet>
                              </Row>
                           </Form>
                           <ConfirmationApproveOrReproveDonor
                              isOpen={modalOpen}
                              donorName={result?.name}
                              onClose={() => setModalOpen(false)}
                              status={actionSelected}
                              onConfirmation={() => {
                                 handleApproval(
                                    donorData,
                                    actionSelected === 'approved',
                                 );
                                 setModalOpen(false);
                              }}
                           />
                        </Container>
                     );
                  }}
               </Formik>

               <CustomModal
                  show={showConfirmModal}
                  onHide={handleConfirmClose}
                  centered
               >
                  <Modal.Header closeButton>
                     <Modal.Title>Confirmación de eliminación</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     ¿Estás seguro de que deseas eliminar este registro, se
                     perdera toda la informacion?
                  </Modal.Body>
                  <Modal.Footer>
                     <Button
                        type="submit"
                        onClick={handleConfirmClose}
                        label="Cancelar"
                     />
                     <Button
                        type="button"
                        onClick={handleConfirmDelete}
                        label="Eliminar"
                     />
                  </Modal.Footer>
               </CustomModal>

               <AttachChildModal
                  isOpen={openModal}
                  onClose={() => setOpenModal(false)}
                  onSubmit={handleCreateAppointment}
               />
            </Fragment>
         )}
      </>
   );
};

export default DonorDetails;

const LoadingContainer = styled.div`
   width: 100%;
   height: 90vh;
   display: flex;
   justify-content: center;
   align-items: center;
`;
const Breadcrumb = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;

   border-bottom: 1px solid #e2e2ea;

   width: 100%;
   padding: 25px 0;
`;

const BreadcrumbBackButton = styled.button`
   border: none;
   background: transparent;
   outline: none;
   padding: 0;

   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.text1.weight};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.sub};

   > img {
      margin-right: 11px;
   }
`;

const BreadcrumbBackButtonIcon = styled.button`
   background: transparent;
`;

const BreadcrumbTitle = styled.p`
   flex: 1;

   margin: 0;

   font-family: ${(props) => props.theme.text.heading1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.heading1.weight};
   font-size: ${(props) => props.theme.text.heading1.size};
   line-height: ${(props) => props.theme.text.heading1.lineHeight};

   color: ${(props) => props.theme.neutral.main};
`;

const Item = styled.div`
   display: flex;
   flex-direction: column;

   margin: 0 35px;
`;

const ItemTitle = styled.div`
   display: flex;
   flex-direction: row;

   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.text1.weight};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.lightness};
`;

const ItemDescription = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;

   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.text1.weight};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.main};

   i {
      font-size: 24px;
      color: ${(props) => props.theme.neutral.lightness};
   }
`;

const SectionHeaderContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;

   height: 40px;
`;

const ContainerPhotoOption = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;

   height: 40px;
`;

const SectionHeaderTitle = styled.p`
   margin: 0;

   font-family: ${(props) => props.theme.text.title.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.title.weight};
   font-size: ${(props) => props.theme.text.title.size};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.neutral.main};
`;

const SectionHeaderButton = styled.button`
   display: flex;
   flex-direction: row;
   align-items: center;

   border: none;
   outline: none;
   background: none;

   font-family: ${(props) => props.theme.text.title.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.title.weight};
   font-size: ${(props) => props.theme.text.title.size};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.colors.brand.secondary.main};
`;

const CommitmentContainer = styled.div<{ empty?: boolean }>`
   display: flex;
   padding: 32px;

   overflow: auto;

   ${(props) =>
      props.empty
         ? css`
              flex-direction: column;

              justify-content: center;
              align-items: center;
           `
         : css`
              flex-direction: row;
           `}

   min-height: 256px;

   background: rgba(29, 173, 201, 0.08);
   border: 1px solid #1dadc9;
   box-sizing: border-box;
   border-radius: 8px;

   &::-webkit-scrollbar {
      width: 10px;
   }

   &::-webkit-scrollbar-track {
      background-color: rgba(29, 173, 201, 0.08);
      border-radius: 100px;
   }

   &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.neutral.lightest};
      border-radius: 100px;
   }
`;

const CommitmentMessage = styled.p`
   max-width: 367px;
   text-align: center;

   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.text1.weight};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.colors.brand.secondary.main};
`;

const CommitmentButton = styled.button`
   margin: auto;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 8px 24px;

   background: ${(props) => props.theme.colors.brand.secondary.main};
   border-radius: 90px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.text1.weight};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.white};
`;

const CommitmentButtonUpdate = styled.button`
   margin: auto;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 8px 24px;

   background: ${(props) => props.theme.colors.brand.secondary.main};
   border-radius: 90px;

   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.text1.weight};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   color: ${(props) => props.theme.neutral.white};
   cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
   opacity: ${(props) => (props.disabled ? 0.5 : 1)};

   &:hover {
      background: ${(props) =>
         !props.disabled && props.theme.colors.brand.secondary.dark};
   }
`;

const CommitmentKid = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   margin-right: 32px;
`;

const ContenDelet = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   margin-right: 32px;
`;

const CommitmentPhotoWrapper = styled.div`
   position: relative;

   &:hover {
      div {
         opacity: 1;
      }
   }
`;

const CommitmentPhoto = styled.img`
   width: 144px;
   height: 144px;
   object-fit: cover;

   filter: drop-shadow(0px 1px 0px #e2e2ea);
   border-radius: 500px;
`;

const CommitmentPhotoOverlay = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;

   position: absolute;
   top: 0;
   left: 0;

   transition: opacity 0.2s ease;
   will-change: opacity;

   width: 100%;
   height: 100%;

   opacity: 0;

   background-color: rgba(0, 0, 0, 0.4);

   border-radius: 500px;

   cursor: pointer;

   i {
      color: ${(props) => props.theme.neutral.white};
      font-size: 32px;
   }
`;

const CommitmentName = styled.p`
   text-align: center;

   font-family: ${(props) => props.theme.text.title.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.title.weight};
   font-size: ${(props) => props.theme.text.title.size};
   line-height: ${(props) => props.theme.text.title.lineHeight};

   color: ${(props) => props.theme.colors.brand.secondary.main};
`;

const DonorPhoto = styled.img`
   width: 100%;
   height: 256px;
   object-fit: cover;
   border-radius: 8px;
`;

const DonorSince = styled.p`
   text-align: right;

   font-family: ${(props) => props.theme.text.text1.family};
   font-style: normal;
   font-weight: ${(props) => props.theme.text.text1.weight};
   font-size: ${(props) => props.theme.text.text1.size};
   line-height: ${(props) => props.theme.text.text1.lineHeight};

   letter-spacing: 0.01em;

   color: ${(props) => props.theme.neutral.lightness};
`;

const PhotoApproveButtons = styled.div`
   display: flex;
   gap: 1rem;
   flex-direction: row;
`;

const getIcon = (positive: boolean, inverse = false) => {
   if (positive) {
      return inverse
         ? '/assets/images/icons/checkboxTrueInverse.svg'
         : '/assets/images/icons/checkboxTrue.svg';
   }

   return inverse
      ? '/assets/images/icons/checkboxFalseInverse.svg'
      : '/assets/images/icons/checkboxFalse.svg';
};

const ButtonApproved = styled.button<ButtonApprovationProps>(
   ({ theme, gotChecked }) => css`
      border: 1px solid ${theme.neutral.lightest};
      background: ${gotChecked
         ? theme.feedback.success.main
         : theme.neutral.background};

      color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
      border-radius: 100px;
      height: 28px;
      width: 28px;

      transition: all 0.2s;

      :active,
      &:hover,
      &:focus {
         background-color: ${theme.feedback.success.main};
         color: ${theme.neutral.white};
      }
      &:disabled {
         opacity: 0.5;
         &:hover {
            cursor: not-allowed;
            background-color: ${gotChecked
               ? theme.feedback.success.main
               : theme.neutral.background};
            color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
         }
      }
   `,
);
const ButtonDisapproved = styled.button<ButtonApprovationProps>(
   ({ theme, gotChecked }) => css`
      border: 1px solid ${theme.neutral.lightest};
      background: ${gotChecked
         ? theme.feedback.error.main
         : theme.neutral.background};
      color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
      border-radius: 100px;
      height: 28px;
      width: 28px;

      transition: all 0.2s;

      &:active,
      &:hover,
      &:focus {
         background-color: ${theme.feedback.error.main};
         color: ${theme.neutral.white};
      }
      &:disabled {
         opacity: 0.5;
         &:hover {
            cursor: not-allowed;
            background-color: ${gotChecked
               ? theme.feedback.error.main
               : theme.neutral.background};
            color: ${gotChecked ? theme.neutral.white : theme.neutral.main};
         }
      }
   `,
);

const Overlay = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1050; /* Bootstrap modal's default z-index is 1040 */
`;

const CustomModal = styled(Modal)`
   .modal-content {
      position: absolute;
      background-color: white;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      z-index: 1060;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   .modal-header,
   .modal-footer {
      display: flex;
      justify-content: center;
      border: none;
   }

   .modal-title {
      width: 100%;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      color: #ff5f00;
   }

   .modal-body {
      text-align: center;
      font-family: 'Poppins', sans-serif;
      padding: 20px 40px;
   }

   .btn-primary,
   .btn-secondary {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 5px;
      transition: background-color 0.3s, border-color 0.3s;
   }

   .btn-primary {
      background-color: #ff5f00;
      border-color: #ff5f00;
   }

   .btn-primary:hover {
      background-color: #ff7929;
      border-color: #ff7929;
   }

   .btn-secondary {
      background-color: #d65000;
      border-color: #d65000;
   }

   .btn-secondary:hover {
      background-color: #ff5f00;
      border-color: #ff5f00;
   }
`;
