import Container from 'components/Container';
import LandingHeader from 'components/LandingHeader';
import { Fragment, useState } from 'react';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { BsFillCaretRightFill } from 'react-icons/bs';
import styled, { css, keyframes } from 'styled-components';
import { BsPlusLg } from 'react-icons/bs';
import Spacer from 'components/Spacer';
import FooterTermsAndUsers from 'components/FooterTermsAndUsers';
import Grupo1 from '../assets/images/Grupo1.png';
import Grupo2 from '../assets/images/Grupo2.png';
import Grupo3 from '../assets/images/Grupo3.png';
import Grupo4 from '../assets/images/Grupo5.svg';
import StepOne from '../assets/images/step-1.png';
import StepTwo from '../assets/images/step-2.png';
import StepThree from '../assets/images/step-3.png';
import StepFour from '../assets/images/step-4.png';
import Slider from 'react-slick';
import Thumb1 from '../assets/images/thumb-1.png';
import Thumb2 from '../assets/images/thumb-2.png';
import Thumb3 from '../assets/images/thumb-3.png';
import Thumb4 from '../assets/images/thumb-4.png';
import Thumb5 from '../assets/images/thumb-5.png';
import Thumb6 from '../assets/images/thumb-6.png';
import Thumb8 from '../assets/images/thumb8.jpg';
import Thumb10 from '../assets/images/thumb10.jpg';
import Thumb11 from '../assets/images/thumb11.jpg';
import LogoElegido from '../assets/images/logo-elegido.png';
import Success1 from '../assets/images/success1.jpg';
import Success2 from '../assets/images/success2.jpg';
import FAQ_image from '../assets/images/FAQ_image.jpg';

import { useFormatMessage } from 'react-intl-hooks';
import useRegionalization from 'hooks/useRegionalization';
import { useDispatch } from 'react-redux';
import { setUser } from '../services/slices/idreferred';

type VideoProps = {
   thumb: string;
   link: string;
};

const LandingPage = (): JSX.Element => {
   const [positionLP, setpositionLP] = useState('');
   const { country } = useRegionalization();
   const t = useFormatMessage();
   const dispatch = useDispatch();
   const [videoLoaded, setVideoLoaded] = useState(false);
   const [firstFaq, setfirstFaq] = useState(false);
   const [towFaq, settowFaq] = useState(false);
   const [threeFaq, setthreeFaq] = useState(false);
   const [fourFaq, setfourFaq] = useState(false);

   const handleImageClick = () => {
      setVideoLoaded(true);
   };

   const urlParams = () => {
      const queryString = window.location.search;
      const paramArray = queryString.substr(1).split('&');
      const codigo = paramArray[0].split('=');
      dispatch(setUser(codigo[1]));
   };

   urlParams();

   const [selectedVideo, setSelectedVideo] = useState<VideoProps>({
      thumb: Thumb1,
      link: 'https://www.youtube.com/embed/H9u2EM6lKBg',
   });

   const [selectedVideoMx, setSelectedVideoMx] = useState<VideoProps>({
      thumb: Thumb10,
      link: 'https://www.youtube.com/embed/gdhSJBYtJyo',
   });

   const [videoList, setVideoList] = useState<VideoProps[]>([
      { thumb: Thumb3, link: 'https://www.youtube.com/embed/5l00v5J7_0o' },
      { thumb: Thumb6, link: 'https://www.youtube.com/embed/jUNxewdobN8' },
      { thumb: Thumb5, link: 'https://www.youtube.com/embed/ukN3WSlxwi8' },
      { thumb: Thumb4, link: 'https://www.youtube.com/embed/OY1EmEa7OQY' },
      { thumb: Thumb2, link: 'https://www.youtube.com/embed/mzjgEV63u0g' },
   ]);

   const [videoListMx, setVideoListMx] = useState<VideoProps[]>([
      { thumb: Thumb10, link: 'https://www.youtube.com/embed/gdhSJBYtJyo' },
      { thumb: Thumb8, link: 'https://www.youtube.com/embed/yjIdkxfD8Dg' },
   ]);

   const handleChangeVideoMx = (item: VideoProps, idx: number) => {
      const newArray = [...videoListMx];
      newArray[idx] = selectedVideoMx;
      setSelectedVideoMx(item);
      setVideoListMx(newArray);
   };

   const handleChangeVideo = (item: VideoProps, idx: number) => {
      const newArray = [...videoList];
      newArray[idx] = selectedVideo;
      setSelectedVideo(item);
      setVideoList(newArray);
   };

   const handleChangeViewFaq = (nameFaq) => {
      if (nameFaq === 'faqOne') {
         setfirstFaq(!firstFaq);
      }
      if (nameFaq === 'faqTwo') {
         settowFaq(!towFaq);
      }
      if (nameFaq === 'faqThree') {
         setthreeFaq(!threeFaq);
      }
      if (nameFaq === 'faqfour') {
         setfourFaq(!fourFaq);
      }
   };

   const refs = {
      counterSummary: useRef(null),
      howItImpact: useRef(null),
      howItWorks: useRef(null),
      successStoryes: useRef(null),
      // Agrega más referencias según sea necesario
   };

   const handleNave = (position: string) => {
      scrollToTarget(position);
   };

   const scrollToTarget = (targetRef) => {
      if (refs[targetRef].current) {
         refs[targetRef].current.scrollIntoView({ behavior: 'smooth' });
      }
   };

   return (
      <Fragment>
         <LandingHeader
            handleNaveLP={(position: string) => {
               handleNave(position);
            }}
         />

         <ConuterSummary ref={refs.counterSummary}>
            <ChosenImg
               src="./assets/LOGO-ELEGIDO-BLANCO-NEGRO.png"
               alt="logo escolhido"
            />
            <TitleChosen>
               ¡Las niñas y niños de La Guajira <br /> quieren elegirte como su
               madrina o padrino!
            </TitleChosen>
            <MissionChosen>
               Elegido es la nueva forma de{' '}
               <BoldText>apadrinar a la niñez de Colombia</BoldText>. Desde
               World Vision le damos el poder a las niñas y niños <br /> más
               vulnerables de La Guajira, de elegir quién cambiará sus vidas
               para siempre a través del apadrinamiento.
            </MissionChosen>
            <VideoSection>
               <SubTitleVide>
                  En este video de menos de un minuto te contamos cómo puedes
                  iniciar esta increíble experiencia:
               </SubTitleVide>
               <div>
                  <SelectedVideo>
                     {videoLoaded ? (
                        <iframe
                           src={selectedVideoMx.link}
                           title="YouTube video player"
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                     ) : (
                        <img
                           src={`${Thumb11}`}
                           alt="Imagen de previsualización del video"
                           onClick={handleImageClick}
                           style={{ cursor: 'pointer' }}
                        />
                     )}
                  </SelectedVideo>
               </div>
            </VideoSection>
            {/* <CustomButtom onClick={scrollToTarget}>
               {t({ id: 'quero ser escolhido' })}
            </CustomButtom> */}
            <CustomLink to="/checkout">
               {t({ id: 'quero ser escolhido' })}
            </CustomLink>
         </ConuterSummary>

         <About>
            <HowItWorks ref={refs.howItImpact}>
               <Container>
                  <Steps>
                     <EachStep typeStep={false}>
                        <img src={Grupo4} />
                        <StepTitle specialTitle={true}>
                           Con un aporte de solo $1.700 diarios:
                        </StepTitle>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Contribuyes en los procesos de desarrollo y
                           mejoramiento de la calidad de vida de la niñez y de
                           sus comunidades
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Promueves una sociedad más justa y segura para la
                           niñez.{' '}
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Ayudas a que la niñez pueda disfrutar una vida plena.
                        </StepText>
                     </EachStep>

                     <EachStep typeStep={false}>
                        <img src={Grupo2} />
                        <StepTitle specialTitle={true}>
                           Tú aporte mensual permite que una niña o niño tenga
                           acceso a:
                        </StepTitle>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Salud y nutrición de calidad.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Agua limpia y saneamiento.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Educación.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Espacios de desarrollo de capacidades.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Escuelas de paz y no violencia.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Protección y crianza con ternura.
                        </StepText>
                     </EachStep>

                     <EachStep typeStep={false}>
                        <img src={Grupo3} />

                        <StepTitle specialTitle={true}>
                           Periódicamente puedes conocer el increíble impacto de
                           tu donación a través de:
                        </StepTitle>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Informes del progreso de la comunidad de tu ahijada/o
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Fotos y videos de la niña o niño que te elija.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Cartas y correspondencia.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Visitas que puedes solicitar.
                        </StepText>
                        <StepText typeAling={true}>
                           <StyleIconMenu />
                           Eventos organizados por World Visión.
                        </StepText>
                     </EachStep>
                  </Steps>
               </Container>
               {/* <CustomButtom onClick={scrollToTarget}>
                  {t({ id: 'quero ser escolhido' })}
               </CustomButtom> */}
               <CustomLink to="/checkout">
                  {t({ id: 'quero ser escolhido' })}
               </CustomLink>
            </HowItWorks>
         </About>

         <About>
            <HowItWorks ref={refs.howItWorks} typeBackGround={true}>
               <Container>
                  <SectionTitle>
                     {t({ id: 'Como o escolhido funciona' })}
                  </SectionTitle>

                  <Steps>
                     <EachStep typeStep={true}>
                        <img src={StepOne} />
                        <StepLink to="/checkout" />
                        <StepTitle>{t({ id: 'Vamos comecar' })}</StepTitle>
                        <StepText>
                           {t({
                              id: 'Cadastrese envie pra gente sua foto e escolha como gostaria de receber sua revelacao fisica ou digital',
                           })}
                        </StepText>
                     </EachStep>

                     <EachStep typeStep={true}>
                        <img src={StepTwo} />

                        <StepTitle>
                           {t({ id: 'Uma crianca ira escolher voce' })}
                        </StepTitle>
                        <StepText>
                           {t({
                              id: 'Voce recebera um email de confirmacao com a explicacao dos proximos passos',
                           })}
                        </StepText>
                     </EachStep>

                     <EachStep typeStep={true}>
                        <img src={StepThree} />

                        <StepTitle>{t({ id: 'Eu ouvi festa' })}</StepTitle>
                        <StepText>
                           {t({
                              id: 'Nossa equipe vai preparar uma festa no campo com toda a seguranca para as criancas escolherem seus padrinhos e madrinhas',
                           })}
                        </StepText>
                     </EachStep>

                     <EachStep typeStep={true}>
                        <img src={StepFour} />

                        <StepTitle>
                           {t({ id: 'Frutos da sua dedicacao' })}
                        </StepTitle>
                        <StepText>
                           {t({
                              id: 'Voce recebera a foto e carta da crianca que te escolheu alem de acompanhar por email as mudancas que sua doacao possibilitara na vida da crianca e de sua comunidade',
                           })}
                        </StepText>
                     </EachStep>
                  </Steps>

                  {/* <CustomLink to="/checkout">
                     {t({ id: 'quero ser escolhido' })}
                  </CustomLink> */}
                  <CustomLink to="/checkout">
                     {t({ id: 'quero ser escolhido' })}
                  </CustomLink>
               </Container>
            </HowItWorks>
            <SuccessStories ref={refs.successStoryes}>
               <Container>
                  <SectionTitle>Elegidos como tú dicen que…</SectionTitle>
                  <AboutBottomContainer>
                     <SuccessImg
                        src={Success1}
                        alt="Fotografia de un niño eligiendo"
                     />
                     <AboutSuccessStorie>
                        {' '}
                        “Te toca el corazón profundo, es muy lindo ver lo que
                        ven los niños y todo lo que se imaginan alrededor de una
                        sola foto. Patrocinar es apoyar a cambiar la vida de las
                        niñas y niños dando un poco de lo que se ha recibido”
                        <br />
                        <br />
                        <BoldText>
                           -Viviana,madrina elegida por Roxana.
                        </BoldText>
                     </AboutSuccessStorie>
                  </AboutBottomContainer>
                  <AboutBottomContainer>
                     <SuccessImg
                        src={Success2}
                        alt="Fotografia de un niño eligiendo"
                     />
                     <AboutSuccessStorie>
                        “Es una alegría, es justamente lo que estaba esperando,
                        ese niño alegre que con todas sus dificultades sigue
                        encaminado en estudiar, en dedicarse a compartir con su
                        familia y en brindarles ese cariño. Me alegra
                        profundamente empezar a ser parte de su vida.”
                        <br />
                        <br />
                        <BoldText>- Juan, padrino elegido por Samuel.</BoldText>
                     </AboutSuccessStorie>
                  </AboutBottomContainer>
               </Container>
            </SuccessStories>
            <FaqSection>
               <Container>
                  <FaqConteiner>
                     <Faq>
                        <SectionTitle>Preguntas frecuentes</SectionTitle>
                        <FaqStep>
                           <FaqTitle>¿Cómo se usan los aportes? </FaqTitle>
                           <ButtonAction
                              onClick={() => {
                                 handleChangeViewFaq('faqOne');
                              }}
                           >
                              <BsPlusLgStyled rotate={firstFaq} />
                           </ButtonAction>
                        </FaqStep>
                        <FaqContent displayElement={firstFaq}>
                           <p>
                              Los aportes que tú realizas no son entregados
                              directamente a la niña o el niña que patrocinas,
                              tu aporte mensual junto con el de los demás
                              patrocinadores se convierte en un fondo común que
                              financiarlos diferentes programas y proyectos que
                              benefician/impactan a: Las niñas y niños de una
                              comunidad. A tu patrocinado y su familia.
                              Adolescentes y jóvenes de una comunidad. Líderes
                              comunitarios. Creación de planes sostenibles a
                              largo plazo con las comunidades y familias,
                              contribuyendo a generar entornos más seguros y
                              protegidos para la niñez vulnerables.
                           </p>
                        </FaqContent>
                        <Spacer bottom={16} />
                        <FaqStep>
                           <FaqTitle>
                              ¿Puedo patrocinar a más de una niña o niño?{' '}
                           </FaqTitle>
                           <ButtonAction
                              onClick={() => {
                                 handleChangeViewFaq('faqTwo');
                              }}
                           >
                              <BsPlusLgStyled rotate={towFaq} />
                           </ButtonAction>
                        </FaqStep>
                        <FaqContent displayElement={towFaq}>
                           <p>
                              Sí. Puedes patrocinar tantos niños como desees. Un
                              gran porcentaje de patrocinadores en Colombia
                              apoyan a más de una niña o niño.
                           </p>
                        </FaqContent>
                        <Spacer bottom={16} />
                        <FaqStep>
                           <FaqTitle>¿Quién controla a World Vision?</FaqTitle>
                           <ButtonAction
                              onClick={() => {
                                 handleChangeViewFaq('faqThree');
                              }}
                           >
                              <BsPlusLgStyled rotate={threeFaq} />
                           </ButtonAction>
                        </FaqStep>
                        <FaqContent displayElement={threeFaq}>
                           <p>
                              WorldVision Colombia cumple con los requerimientos
                              de ley en la auditoría de la administración de los
                              recursos y cuenta, además, con controles propios y
                              externos. Somos una organización con controles y
                              auditorías propias, auditada internacionalmente y
                              con un sistema de rendición de cuentas
                              transparente. La mejor manera para evidenciar
                              nuestro impacto en la vida de las niñas, niños,
                              sus familias y comunidades es la oportunidad que
                              tendrás de participar como patrocinador en las
                              diferentes iniciativas que promueve la
                              organización para dar cuenta de la intervención en
                              las comunidades que acompañamos gracias a tus
                              aportes
                           </p>
                        </FaqContent>
                        <Spacer bottom={16} />
                        <FaqStep>
                           <FaqTitle>
                              ¿Mis aportes realizados a WV son deducibles de
                              impuestos?
                           </FaqTitle>
                           <ButtonAction
                              onClick={() => {
                                 handleChangeViewFaq('faqfour');
                              }}
                           >
                              <BsPlusLgStyled rotate={fourFaq} />
                           </ButtonAction>
                        </FaqStep>
                        <FaqContent displayElement={fourFaq}>
                           <p>
                              Sí. Puedes solicitar el certificado de tus
                              donaciones, comunicándote con nuestras líneas
                              (601) 314 48 70 en Bogotá, en nuestra línea
                              gratuita nacional 01 8000 9 10000 o vía whatsapp
                              (+57) 313 479 8144 también puede escribirnos al
                              correo electrónico mipatrocinio@wvi.org. *Debes
                              solicitar tu certificado con 8 días hábiles de
                              anticipación. ¿Cómo selecciona World Vision a las
                              niñas/os que serán patrocinados? WorldVision,
                              después de realizar estudios sobre los índices de
                              pobreza y vulnerabilidad, identifica un área
                              geográfica donde viven familias en estas
                              condiciones, luego efectúa un análisis más
                              detallado de la comunidad de esa área y vincula a
                              las familias que tienen mayores necesidades, sin
                              diferenciarlos por su raza, etnia y creencias
                              políticas o religiosas.
                           </p>
                        </FaqContent>
                     </Faq>
                     <ImgFaq src={FAQ_image} alt="Imagen de niños reunidos" />
                  </FaqConteiner>
               </Container>
            </FaqSection>
         </About>

         <FooterTermsAndUsers />
      </Fragment>
   );
};

const Banner = styled.div<{ isMx: boolean }>`
   display: none;
   background-image: ${(props) =>
      props.isMx
         ? 'url(./assets/images/banner.jpg)'
         : 'url(./assets/images/banner-background.png)'};
   height: 800px;
   background-repeat: no-repeat;
   background-size: cover;

   @media (max-width: 1199px) {
      background-position: center;
      height: 650px;
   }

   @media (max-width: 772px) {
      background-position: 75% 0%;
      height: 650px;
   }
`;
const StepSprit = styled.div`
   height: 60px;
`;

const BannerContent = styled.div`
   padding-top: 160px;
   display: flex;
   justify-content: space-between;
   align-items: flex-end;

   @media (max-width: 767px) {
      padding-top: 320px;
      display: block;
   }
`;

const BannerText = styled.div``;

const ChosenImg = styled.img(
   ({ theme }) => css`
      margin-bottom: ${theme.spacing.xs};
      margin-top: ${theme.spacing.xl};
      max-width: 200px;
      width: 100%;
      object-fit: contain;
   `,
);

const BannerTitle = styled.h1(
   ({ theme }) => css`
      margin-bottom: ${theme.spacing.xl};
      font-size: 56px;
      line-height: 64px;
      font-family: ${theme.text.heading1.family};
      color: ${theme.neutral.white};
      font-weight: ${theme.text.title.weight};

      @media (max-width: 767px) {
         font-size: ${theme.text.heading1.size};
         line-height: ${theme.text.heading1.size};
      }
   `,
);

const BannerStamp = styled.div(
   ({ theme }) => css`
      @media (max-width: 767px) {
         margin-top: ${theme.spacing.md};
      }
   `,
);

const Stamp = styled.img``;

const OutilineLink = styled(Link)`
   align-items: center;
   padding: 16px 24px;
   background: ${({ theme }) => theme.neutral.white};
   border-radius: 90px;
   border: 2px solid ${({ theme }) => theme.colors.brand.primary.main};
   text-transform: capitalize;
   font-family: ${({ theme }) => theme.text.title.family};
   font-size: ${({ theme }) => theme.text.heading2.size};
   font-weight: ${({ theme }) => theme.text.heading2.weight};
   line-height: ${({ theme }) => theme.text.title.lineHeight};
   color: ${({ theme }) => theme.colors.brand.primary.main};
   text-decoration: none;
   display: inline-block;
   transition: all ease 300ms;

   &:hover {
      background: ${({ theme }) => theme.colors.brand.primary.main};
      color: ${({ theme }) => theme.neutral.white};
   }
`;

const CustomLink = styled(Link)`
   align-items: center;
   padding: 16px 24px;
   background: ${({ theme }) => theme.colors.brand.primary.main};
   border-radius: 90px;
   border: 2px solid ${({ theme }) => theme.colors.brand.primary.main};
   text-transform: capitalize;
   font-family: ${({ theme }) => theme.text.title.family};
   font-size: ${({ theme }) => theme.text.heading2.size};
   font-weight: ${({ theme }) => theme.text.heading2.weight};
   line-height: ${({ theme }) => theme.text.title.lineHeight};
   color: ${({ theme }) => theme.neutral.white};
   text-decoration: none;
   display: inline-block;
   transition: all ease 300ms;
   margin-bottom: 30px;

   &:hover {
      background: ${({ theme }) => theme.neutral.white};
      color: ${({ theme }) => theme.colors.brand.primary.main};
   }
`;
const CustomButtom = styled.button(
   ({ theme }) => css`
      align-items: center;
      padding: 16px 24px;
      background: ${({ theme }) => theme.colors.brand.primary.main};
      border-radius: 90px;
      border: 2px solid ${({ theme }) => theme.colors.brand.primary.main};
      margin-bottom: 30px;
      text-transform: capitalize;
      font-family: ${({ theme }) => theme.text.title.family};
      font-size: ${({ theme }) => theme.text.heading2.size};
      font-weight: ${({ theme }) => theme.text.heading2.weight};
      line-height: ${({ theme }) => theme.text.title.lineHeight};
      color: ${({ theme }) => theme.neutral.white};
      text-decoration: none;
      display: inline-block;
      transition: all ease 300ms;

      &:hover {
         background: ${({ theme }) => theme.neutral.white};
         color: ${({ theme }) => theme.colors.brand.primary.main};
      }
   `,
);

const ImageLink = styled(Link)`
   background-position: 60% center;
   background-repeat: no-repeat;
   width: 250px;
   background: transparent;
   height: 65px;
   position: absolute;
   margin-left: -240px;
`;

const StepLink = styled(Link)`
   background-position: 60% center;
   background-repeat: no-repeat;
   width: 250px;
   height: 220px;
   background: transparent;
   position: absolute;
   margin-left: -240px;
   border-radius: 90px;
`;

const About = styled.section`
   padding: 10px 0;
   text-align: center;

   @media (max-width: 767px) {
      padding: 20px 0;
   }
`;

const AboutTopContainer = styled.div(
   ({ theme }) => css`
      margin-bottom: ${theme.spacing.xl};
   `,
);

const SectionTitle = styled.h2(
   ({ theme }) => css`
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
      font-size: ${theme.text.heading1.size};
      line-height: ${theme.text.heading1.lineHeight};
      max-width: 720px;
      margin: 0 auto ${theme.spacing.xl};
      color: ${theme.neutral.main};
   `,
);

const SuccessStories = styled.div`
   background-color: #fff3eb;
   margin-top: 30px;
   padding: 2% 4%;
`;

const AboutBottomContainer = styled.div(
   ({ theme }) => css`
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 0 150px 30px 150px;
      position: relative;
      border: 1px solid #ff6b00;
      border-radius: 15px;

      @media (max-width: 995px) {
         margin: 0 70px 30px 70px;
      }

      @media (max-width: 768px) {
         flex-direction: column;
         margin: 0 25px 30px 25px;
      }
   `,
);

const SuccessImg = styled.img`
   border-radius: 15px;
   max-width: 250px;
   width: 100%;
   object-fit: contain;

   @media (max-width: 768px) {
      margin: 15px 0px;
   }
`;

const AboutSuccessStorie = styled.p`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 294px;
   align-items: center;
   justify-content: center;
   border-radius: 15px;
   padding: 25px;
   font-family: Inter;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   color: #8d8f96;

   @media (max-width: 768px) {
      margin-top: 10px;
      height: auto;
   }
`;

const ImgContainer = styled.div(
   ({ theme }) => css`
      width: 34%;

      .imgDesk {
         display: block;
         margin: 0 auto ${theme.spacing.xl};
      }

      .imgMobile {
         display: none;
         margin: 0 auto ${theme.spacing.xl};
      }

      img {
         margin-bottom: ${theme.spacing.xl};
      }

      @media (max-width: 767px) {
         width: 100%;

         &:first-child {
            margin-bottom: ${theme.spacing.xxs};
         }

         .imgDesk {
            display: none;
         }

         .imgMobile {
            display: block;
            width: 100%;
         }
      }
   `,
);

const Title = styled.h3(
   ({ theme }) => css`
      font-family: ${theme.text.heading2.family};
      font-weight: ${theme.text.heading2.weight};
      font-size: ${theme.text.heading2.size};
      line-height: ${theme.text.heading2.lineHeight};
      margin: 0 auto ${theme.spacing.sm};
   `,
);

const Text = styled.p(
   ({ theme }) => css`
      font-family: ${theme.text.text1.family};
      font-weight: ${theme.text.text1.weight};
      font-size: ${theme.text.text1.size};
      line-height: ${theme.text.text1.lineHeight};
      color: ${theme.neutral.sub};
   `,
);

const HeartBow = styled.img`
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   top: 20%;
   width: 390px;

   @media (max-width: 991px) {
      width: 250px;
   }

   @media (max-width: 767px) {
      display: none;
   }
`;

const HowItWorks = styled.section<{ typeBackGround?: boolean }>`
   background-color: ${({ typeBackGround }) =>
      typeBackGround ? '' : '#fff3eb'};
   padding: 10px 0;
   text-align: center;

   h2 {
      margin-bottom: 40px;
   }

   @media (max-width: 767px) {
      padding: 10px 0;

      h2 {
         margin-bottom: 40px;
      }
   }
`;
const Steps = styled.div(
   ({ theme }) => css`
      display: flex;
      align-items: flex-start;
      padding: 0 50px;
      margin-bottom: ${theme.spacing.xl};

      @media (max-width: 1199px) {
         flex-wrap: wrap;
         padding: 0;
         justify-content: space-between;
      }

      @media (max-width: 767px) {
         margin-bottom: ${theme.spacing.md};
      }
   `,
);

const EachStep = styled.div<{ typeStep }>`
   flex: 1;
   background-color: ${({ typeStep }) => (typeStep ? '' : 'white')};
   height: ${({ typeStep }) => (typeStep ? '580px' : '650px')};
   border-radius: 10px;
   margin-top: ${({ typeStep }) => (typeStep ? '' : '80px')};
   padding: ${({ typeStep }) => (typeStep ? '5px' : '0px')};

   &:not(:last-child) {
      margin-right: 80px;
   }

   img {
      margin-bottom: 24px;
      margin-top: ${({ typeStep }) => (typeStep ? '0' : '-80px')};
   }
   @media (max-width: 1200px) {
      width: ${({ typeStep }) => (typeStep ? '' : '30%')};
      flex: ${({ typeStep }) => (typeStep ? '1' : 'inherit')};
      height: ${({ typeStep }) => (typeStep ? '530px' : '700px')};
      margin-top: ${({ typeStep }) => (typeStep ? '0' : '60px')};

      img {
         max-width: ${({ typeStep }) => (typeStep ? '' : '100%')};
         height: ${({ typeStep }) => (typeStep ? '' : '300px')};
      }

      &:not(:last-child) {
         margin-right: 0;
      }
   }

   @media (max-width: 991px) {
      flex: ${({ typeStep }) => (typeStep ? 'inherit' : 'inherit')};
      width: ${({ typeStep }) => (typeStep ? '50%' : '30%')};
      height: ${({ typeStep }) => (typeStep ? 'auto' : '830px')};
      margin-top: ${({ typeStep }) => (typeStep ? '0' : '60px')};

      img {
         max-width: ${({ typeStep }) => (typeStep ? '' : '100%')};
         height: ${({ typeStep }) => (typeStep ? 'auto' : '300px')};
      }

      &:not(:last-child) {
         margin-right: 0;
      }
   }

   @media (max-width: 767px) {
      width: 100%;
      height: auto;
      padding-bottom: 30px;

      &:not(:last-child) {
         margin-bottom: 0px;
      }
   }
`;

const StepTitle = styled.h3<{ specialTitle?: boolean }>`
   font-family: Poppins;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #353945;
   margin-bottom: ${({ specialTitle }) => (specialTitle ? '60px' : '20px')};
   height: 48px;
   padding-left: 20px;
   padding-right: 20px;

   @media (max-width: 1199px) {
      margin-bottom: ${({ specialTitle }) => (specialTitle ? '60px' : '')};
   }

   @media (max-width: 970px) {
      margin-bottom: ${({ specialTitle }) => (specialTitle ? '110px' : '')};
   }

   @media (max-width: 767px) {
      margin-bottom: ${({ specialTitle }) => (specialTitle ? '60px' : '')};
   }
`;
const StepText = styled.p<{ typeAling?: boolean }>`
   font-family: Inter;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   color: #8d8f96;
   padding: 10px 10px 0px 30px;
   text-align: ${({ typeAling }) => (typeAling ? 'left' : 'center')};
`;

const Video = styled.section(
   ({ theme }) => css`
      padding: 120px 0;
      background-color: ${theme.colors.brand.primary.main};

      @media (max-width: 767px) {
         padding: 80px 0;
      }
   `,
);

const SelectedVideo = styled.div(
   ({ theme }) => css`
      margin-bottom: ${theme.spacing.xl};

      iframe {
         width: 100%;
      }
      img {
         width: 100%;
      }

      @media (max-width: 3000px) {
         iframe {
            height: 450px;
         }
         img {
            height: 450px;
         }
      }

      @media (max-width: 1199px) {
         iframe {
            height: 350px;
         }
         img {
            height: 350px;
         }
      }

      @media (max-width: 975px) {
         iframe {
            height: 250px;
         }
         img {
            height: 250px;
         }
      }

      @media (max-width: 767px) {
         iframe {
            height: 200px;
         }
         img {
            height: 200px;
         }
      }

      @media (max-width: 575px) {
         iframe {
            height: 150px;
         }
         img {
            height: 150px;
         }
      }
   `,
);

const VideoList = styled.div`
   img {
      width: 100%;
      padding: 0 10px;
   }
`;

const EachVideo = styled.div`
   cursor: pointer;
   @media (max-width: 3382px) {
      height: 132px;
   }

   @media (max-width: 1382px) {
      height: 110px;
   }

   @media (max-width: 1199px) {
      height: 160px;
   }

   @media (max-width: 975px) {
      height: 119px;
   }

   @media (max-width: 767px) {
      height: 120px;
   }

   @media (max-width: 575px) {
      height: 119px;
   }
`;

const ConuterSummary = styled.div(
   ({ theme }) => css`
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 70%;
   `,
);

const TitleChosen = styled.h1(
   ({ theme }) => css`
      font-family: ${theme.text.heading1.family};
      color: ${theme.neutral.main};
      text-align: center;
      margin-bottom: ${theme.spacing.md};

      @media (max-width: 3000px) {
         font-weight: ${theme.text.heading1.weight};
         line-height: ${theme.text.heading1.lineHeight};
         font-size: ${theme.text.heading1.size};
      }

      @media (max-width: 770px) {
         font-weight: ${theme.text.heading2.weight};
         line-height: ${theme.text.heading2.lineHeight};
         font-size: 26px;
      }

      @media (max-width: 480px) {
         font-weight: ${theme.text.heading2.weight};
         line-height: ${theme.text.heading2.lineHeight};
         font-size: ${theme.text.heading2.size};
      }
   `,
);

const MissionChosen = styled.p(
   ({ theme }) => css`
      font-family: ${theme.text.title.family};
      color: ${theme.neutral.main};
      margin-bottom: ${theme.spacing.md};
      text-align: center;

      @media (max-width: 3000px) {
         font-weight: 500;
         line-height: 18px;
         font-size: 16px;
      }

      @media (max-width: 770px) {
         font-weight: 500;
         line-height: 16px;
         font-size: 14.5px;
      }

      @media (max-width: 480px) {
         font-weight: 500;
         line-height: 14px;
         font-size: 13px;
      }
   `,
);

const BoldText = styled.span`
   font-weight: 800;
`;

const SubTitleVide = styled.p(
   ({ theme }) => css`
      font-family: ${theme.text.title.family};
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.main};
      margin-bottom: ${theme.spacing.md};
      text-align: center;

      @media (max-width: 3000px) {
         font-size: 19px;
         line-height: 18px;
      }

      @media (max-width: 770px) {
         font-size: 16px;
         line-height: 16px;
      }

      @media (max-width: 480px) {
         font-size: 14px;
         line-height: 14px;
      }
   `,
);

const VideoSection = styled.div``;

const FaqSection = styled.div``;

const ButtonAction = styled.button`
   background-color: transparent;
`;

const StyleIconMenu = styled(BsFillCaretRightFill)`
   color: #ff5f00;
   font-size: 12px;
`;

const BsPlusLgStyled = styled(BsPlusLg)<{ rotate: any }>`
   color: #ff5f00;
   font-size: 18px;
   font-weight: bold;
   transition: transform 0.3s ease;
   transform: ${(props) => (props.rotate ? 'rotate(45deg)' : 'rotate(0deg)')};
`;

const Faq = styled.section(
   ({ theme }) => css`
      width: 100%;
      padding: 80px 0;
      text-align: center;

      @media (max-width: 767px) {
         padding: 50px 0;
      }
   `,
);

const FaqConteiner = styled.div`
   display: flex;
   flex-direction: row;

   @media (max-width: 1230px) {
      flex-direction: column;
   }
`;

const FaqStep = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-between;
   padding-bottom: 5px;
   border-bottom: 2px solid grey;
`;

const ImgFaq = styled.img`
   padding: 5% 0% 3% 3%;
   margin: 0px auto;
   max-width: 650px;
   max-height: 740px;
   width: auto;

   @media (max-width: 500px) {
      max-width: 300px;
   }
`;

const FaqTitle = styled.h3`
   font-family: Poppins;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #353945;
   padding-left: 20px;
   padding-right: 20px;
   text-align: left;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FaqContent = styled.div<{ displayElement: boolean }>`
   display: ${(props) => (props.displayElement ? 'flex' : 'none')};
   animation: ${(props) => (props.displayElement ? fadeIn : 'none')} 0.3s
      ease-in-out;
   width: 100%;
   align-items: center;
   justify-content: flex-start;
   border-radius: 15px;
   padding: 25px;
   font-family: Inter;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   color: #8d8f96;

   p {
      margin: 0; /* Elimina el margen si es necesario */
      text-align: left; /* Asegura que el texto esté alineado a la izquierda */
   }
`;
export default LandingPage;
