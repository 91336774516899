import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'services/api'

interface listRevelationsInterface {
   status: string
   paginate: {
      has_more: boolean
      page: number
      total: number
   }
   results: [
      {
         digital_revelation: string
         event_date: Date | null
         event_id: number
         physical_revelation: string
         physical_revelation_total: string
         title: string
      },
   ]
}

interface listRevelationsRequest {
   pg: number
   pp: number
   term?: string
}

export const listRevelations = createAsyncThunk(
   'listRevelations',
   async ({ pg, pp, term }: listRevelationsRequest) => {
      return await api
         .get(`/revelation?&pg=${pg}&pp=${pp}${term ? `&term=${term}` : ''}`)
         .then((res) => res.data)
   },
)

const listRevelationsSlice = createSlice({
   name: 'listRevelations',
   initialState: {
      status: 'idle',
      paginate: {
         has_more: false,
         page: 0,
         total: 0,
      },
      results: [],
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(listRevelations.pending, (state) => {
         state.status = 'loading'
      })
      builder.addCase(
         listRevelations.fulfilled,
         (state, action: PayloadAction<listRevelationsInterface>) => {
            state.status = 'ok'
            state.results = action.payload.results
            state.paginate = action.payload.paginate
         },
      ),
         builder.addCase(listRevelations.rejected, (state) => {
            state.status = 'rejected'
         })
   },
})

export default listRevelationsSlice.reducer
