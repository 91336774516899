import styled, { css } from 'styled-components/macro';
import { useFormatMessage } from 'react-intl-hooks';
import theme from '../styles/theme';
import routes from '../routes/routes.json';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import Container from './Container';
import DropdownUser from './DropdownUser';
import useRegionalization from 'hooks/useRegionalization';
import ErrorBoundary from '../pages/dist/errorBoundari.jsx';

// import { Notification } from './Notification/Notification'
// import { useEffect } from 'react'

const AdminHeader = (): JSX.Element => {
   const t = useFormatMessage();
   const { location } = useHistory();
   const params = useParams();
   const { country } = useRegionalization();
   return (
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
         <HeaderCustom>
            <Container>
               <Content>
                  {country === 'mx' ? (
                     <Img
                        src="/assets/images/logo-chosen.png"
                        alt="Logo Visão Mundial"
                     />
                  ) : (
                     <Img
                        src="/assets/images/logo-vmb.svg"
                        alt="Logo Visão Mundial"
                     />
                  )}

                  <DivFlex>
                     <ItemMenu
                        to={routes.approvals}
                        selected={
                           location.pathname.indexOf(routes.approvals) > -1
                        }
                     >
                        <img src={`${theme.pathIcons}/validation.svg`} />
                        <Titles>{t({ id: 'Aprovacoes' })}</Titles>
                     </ItemMenu>

                     {/* <ItemMenu
                     to={routes.packages}
                     selected={
                        location.pathname.indexOf(routes.packages) > -1 ||
                        location.pathname.indexOf(
                           routes.packageDetails.replace(
                              ':packageID',
                              location.pathname.replace(/[^0-9]/g, ''),
                           ),
                        ) > -1
                     }
                  >
                     <img src={`${theme.pathIcons}/archive.svg`} />
                     <Titles>{t({ id: 'Pacotes' })}</Titles>
                  </ItemMenu> */}

                     <ItemMenu
                        to={routes.revelations}
                        selected={
                           location.pathname.indexOf(routes.revelations) > -1 ||
                           location.pathname.indexOf(
                              routes.revelationDetails.replace(
                                 ':revelationID',
                                 location.pathname.replace(/[^0-9]/g, ''),
                              ),
                           ) > -1
                        }
                     >
                        <img src={`${theme.pathIcons}/heart.svg`} />
                        <Titles>{t({ id: 'Revelacoes' })}</Titles>
                     </ItemMenu>

                     <ItemMenu
                        to={routes.donors}
                        selected={location.pathname.indexOf(routes.donors) > -1}
                     >
                        <img src={`${theme.pathIcons}/user.svg`} />
                        <Titles>{t({ id: 'Doadores' })}</Titles>
                     </ItemMenu>

                     <ItemMenu
                        to={routes.utils}
                        selected={location.pathname.indexOf(routes.utils) > -1}
                     >
                        <img src={`${theme.pathIcons}/archive.svg`} />
                        <Titles>{t({ id: 'Utils' })}</Titles>
                     </ItemMenu>
                  </DivFlex>

                  <DivFlex>
                     {/* <Notification /> */}
                     <DropdownUser />
                  </DivFlex>
               </Content>
            </Container>
         </HeaderCustom>
      </ErrorBoundary>
   );
};

const HeaderCustom = styled.header(
   ({ theme }) => css`
      box-shadow: inset 0px -1px 0px ${theme.neutral.lightest};
      background-color: ${theme.neutral.white};
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
   `,
);

const Content = styled.div(
   ({ theme }) => css`
      width: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      height: ${theme.spacing.xxl};
      padding: 0 ${theme.spacing.xs} 0 ${theme.spacing.xs};
   `,
);

const Titles = styled.p(
   ({ theme }) => css`
      font-family: ${theme.text.title.family};
      line-height: ${theme.text.title.lineHeight};
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.light};
      font-size: ${theme.text.title.size};
   `,
);

const DivFlex = styled.div(
   ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      > :nth-last-child(n + 2) {
         margin-right: ${theme.spacing.lg};
      }
   `,
);

const Img = styled.img`
   object-fit: contain;
   max-width: 146px;
   width: 100%;
`;

const ItemMenu = styled(NavLink)<{ selected: boolean }>(
   ({ theme, selected }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      cursor: pointer;
      text-decoration: none;

      > :nth-last-child(n + 2) {
         margin-right: ${theme.spacing.xs};
      }

      ${(props) =>
         selected == true &&
         css`
            ::after {
         background: ${theme.colors.brand.primary.main};
         border-radius: 10px 10px 0 0;
         position: absolute;
         content: '';
         width: 100%;
         height: 3px;
         bottom: 1px;
         left: 0;
         `}
   `,
);

export default AdminHeader;
