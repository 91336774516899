import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import FilterSelect from 'components/FilterSelect';
import theme from '../styles/theme';
import { useFormatMessage } from 'react-intl-hooks';
import ReactLoading from 'react-loading';
import routes from '../routes/routes.json';
import { useDispatch, useSelector } from 'react-redux';
import { listDonors } from 'services/slices/listDonors';
import { RootState } from 'services/store';
import debounce from 'lodash.debounce';
import BadgeStatus from '../components/BadgeStatus';
import Pagination from 'components/Pagination';
import Container from 'components/Container';
import NoResult from 'components/NoResult';
import { useHistory } from 'react-router';
import useRegionalization from '../hooks/useRegionalization';
import Button, { BackButton } from 'components/Button';
import Spinner from 'components/spinner';
import ExcelUploader from 'components/ExcelUploader';

import axios from 'axios';
const baseURL = process.env.REACT_APP_API_URL;

type Item = {
   id: number;
   name: string;
   simma_donor_id: string;
   donor_media: {
      id: number;
      donor_id: number;
      media_source: string;
      media_type: string;
   };
   last_donation: {
      id: number;
      donor_id: number;
      donation_status: string;
      child_quantity: number;
      payment_date: string;
   };
};

const generarConsolidadoJSON = () => {
   return axios.get(baseURL + 'inf-elegidos');
};

const fineImagesById = (id) => {
   return axios.get(baseURL + 'decobase64/' + id);
};

const convertToCSV = (objArray: object[]) => {
   const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
   let str = '';

   const columnNames = [
      'id',
      'phone',
      'address_city',
      'address_street',
      'birthdate',
      'email',
      'gender',
      'first_name',
      'last_name',
      'referral_code',
      'identificationNumber',
      'identificationType',
      'date',
      'payId',
      'payment_method_id',
      'card_emitter',
      'transaction_id',
      'transaction_status',
      'transaction_detail',
      'transaction_user_typeDoc',
      'transaction_user_document',
      'transaction_amount',
   ];
   str += columnNames.join(',') + '\r\n';

   for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const key in array[i]) {
         if (line !== '') line += ',';
         line += array[i][key];
      }
      str += line + '\r\n';
   }

   return str;
};

const downloadCSV = (csv: string, filename: string) => {
   const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
   const csvURL = URL.createObjectURL(csvData);
   const tempLink = document.createElement('a');
   tempLink.href = csvURL;
   tempLink.setAttribute('download', filename);
   tempLink.click();
};

const Utils = (): JSX.Element => {
   const t = useFormatMessage();
   const dispatch = useDispatch();
   const history = useHistory();
   const { country } = useRegionalization();
   const [loading, setLoading] = useState(false);
   const [loadingImage, setLoadingImage] = useState(false);

   const { result, loadingStatus, paginate } = useSelector(
      (state: RootState) => ({
         result: state.listDonors.results,
         loadingStatus: state.listDonors.status,
         paginate: state.listDonors.paginate,
      }),
   );
   const [donationStatus, setDonationStatus] = useState<string>('all');
   const [term, setTerm] = useState<string>('');
   const [pg, setPg] = useState<number>(1);
   const [pp] = useState<number>(country === 'mx' ? 9 : 15);
   const [id, setId] = useState<string>('');
   const [imageSrc, setImageSrc] = useState<string | null>(null);
   const [imageName, setImageName] = useState<string | null>(null);
   const [imageMsj, setImageMsj] = useState<string | null>(null);
   const [error, setError] = useState<string | null>(null);
   const [lastPage, setLastPage] = useState<number>(0);

   useEffect(() => {
      dispatch(
         listDonors({
            donationStatus: donationStatus,
            pp: pp,
            pg: pg,
            term: term,
         }),
      );
   }, [term, donationStatus, pg]);

   useEffect(() => {
      setLastPage(/*NUMBERS OF PAGES */ Math.ceil(paginate.total / pp));
   }, [paginate]);

   const cleanFilters = () => {
      setDonationStatus('all');
      setTerm('');
   };

   const searchFilter = debounce((event) => {
      if (event.target.value.length > 2 || event.target.value.length == 0) {
         setTerm(event.target.value);
      }
   }, 500);

   const handleConsultar = () => {
      setLoadingImage(true);
      setError(null);
      fineImagesById(id)
         .then((base64) => {
            setImageSrc(base64.data.image);
            setImageName(base64.data.name);
            setError(base64.data.msj);
            setLoadingImage(false);
         })
         .catch((err) => {
            setError(err.msj);
            setImageSrc(null);
            setLoadingImage(false);
         });
   };

   const handleDownload = () => {
      setLoadingImage(true);
      if (imageSrc) {
         const link = document.createElement('a');
         link.href = `https://chosenapi.worldvision.co/image${imageSrc}`;
         link.download = imageName;
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
         setLoadingImage(false);
      }
   };

   const handleSubmit = async () => {
      setLoading(true);
      try {
         const response = await generarConsolidadoJSON();
         const responseData: object[] = response.data;
         const csv = convertToCSV(responseData);
         const filename = 'elegidos.csv';
         downloadCSV(csv, filename);
         setLoading(false);
      } catch (e) {
         return console.error('Error getting identificationTypes: ', e);
      }
   };

   return (
      <Container>
         <Content>
            <H1>{t({ id: 'Utils' })}</H1>
            <H3>Informe Consolidado Elegidos</H3>
            <ButtonRow>
               <Button
                  type="button"
                  label={t({ id: 'Descargar' }).toString()}
                  onClick={() => handleSubmit()}
               />
            </ButtonRow>
            <SpinnerDIv>{loading && <Spinner />}</SpinnerDIv>
            <LineHorizontal />
         </Content>
         <Content>
            <H3>Buscar Fotografía</H3>
            <Input
               value={id}
               type="text"
               placeholder={t({ id: 'Id' }).toString()}
               onChange={(e) => setId(e.target.value)}
            ></Input>
            <ButtonRow>
               <Button
                  type="button"
                  label={t({ id: 'Buscar' }).toString()}
                  onClick={handleConsultar}
               />
            </ButtonRow>
            <SpinnerDIv>{loadingImage && <Spinner />}</SpinnerDIv>
            {imageMsj && <p>{imageMsj}</p>}
            {imageSrc && (
               <ImageGenerate
                  src={
                     imageSrc
                        ? `https://chosenapi.worldvision.co/image${imageSrc}`
                        : `https://chosenapi.worldvision.co/image/static/images/sinImagen.png`
                  }
                  alt="Imagen consultada"
               ></ImageGenerate>
            )}
            {imageSrc && (
               <button onClick={handleDownload}>Descargar imagen</button>
            )}
            {error && <ErrorMessage>Error : {error}</ErrorMessage>}
            <LineHorizontal />
         </Content>
         <Content>
            <H3>Cargar Patrocinadores</H3>
            <ExcelUploader />
         </Content>
      </Container>
   );
};

const ErrorMessage = styled.p(
   ({ theme }) => css`
      padding-top: ${theme.spacing.xs};
      font-size: 12px;
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
);

const ImageGenerate = styled.img`
   margin-top: 2%;
   width: 40%;
`;

const LoadingContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const ButtonRow = styled.div`
   text-align: right;
   width: 20%;
   display: flex;
`;

const SpinnerDIv = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;

const ItemListPhoto = styled.img`
   cursor: pointer;
   width: 120px;
   height: 88px;
   object-fit: cover;
`;

const Content = styled.div(
   ({ theme }) => css`
      padding-top: 36px;
      flex-direction: column;
      align-items: center;
      display: flex;
   `,
);

const H1 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.xl};
      font-size: ${theme.text.heading1.size};
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
);

const H3 = styled.h1(
   ({ theme }) => css`
      padding-bottom: ${theme.spacing.md};
      font-size: 15px;
      color: ${theme.neutral.main};
      font-family: ${theme.text.heading1.family};
      font-weight: ${theme.text.heading1.weight};
   `,
);

const LineVertical = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin-right: ${theme.spacing.lg};
      height: 32px;
      width: 2px;
   `,
);

const LineHorizontal = styled.div(
   ({ theme }) => css`
      background-color: ${theme.neutral.lightest};
      margin: ${theme.spacing.md} 0;
      width: 100%;
      height: 2px;
   `,
);

const DivGrid = styled.div(
   ({ theme }) => css`
      grid-template-columns: 2fr 5fr 1.5fr 1.5fr 2fr 1fr;
      margin-bottom: ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      width: 100%;
      grid-gap: 20px;
      align-items: center;
      display: grid;
   `,
);

const TitleTable = styled.p(
   ({ theme }) => css`
      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};
      color: ${theme.neutral.lightness};
   `,
);

const TextTableBold = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
      font-weight: ${theme.text.title.weight};
   `,
);

const TextTable = styled(TitleTable)(
   ({ theme }) => css`
      color: ${theme.neutral.main};
   `,
);

const ItemList = styled(DivGrid)(
   ({ theme }) => css`
      background-color: ${theme.neutral.white};
      margin-bottom: ${theme.spacing.xs};
      box-shadow: 0px 1px 0px ${theme.neutral.lightest};
      border-radius: 8px;
      border: 1px solid transparent;
      transition: border ease 0.3s;
      will-change: border;

      &:hover {
         border: 1px solid ${theme.colors.brand.secondary.main};
      }
   `,
);

const List = styled.div(
   ({ theme }) => css`
      width: 100%;
      > :last-child {
         margin-bottom: ${theme.spacing.xl};
      }
   `,
);

const ButtonList = styled.button(
   ({ theme }) => css`
      background: rgba(255, 95, 0, 0.08);
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
      font-family: ${theme.text.heading2.family};
      color: ${theme.colors.brand.primary.main};
      font-weight: ${theme.text.title.weight};
      font-size: ${theme.text.title.size};
      border-radius: ${theme.spacing.xxl};
      transition: all ease 0.3s;

      &:hover {
         color: ${theme.neutral.white};
         background: ${theme.colors.brand.primary.main};
      }
   `,
);

const DivFlex = styled.div`
   display: flex;
`;

const DivFlexBetween = styled.div`
   display: flex;
   justify-content: space-between;
`;

const DivFlexAround = styled.div`
   display: flex;

   > img {
      margin-right: 16px;
   }
`;

const Filter = styled.button(
   ({ theme }) => css`
      display: flex;
      align-items: center;
      font-family: ${theme.text.title.family};
      font-size: ${theme.text.title.size};
      line-height: ${theme.text.title.lineHeight};
      font-weight: ${theme.text.title.weight};
      color: ${theme.neutral.lightness};
      background: transparent;

      :nth-last-child(n + 2) {
         padding-right: ${theme.spacing.lg};
      }

      > span {
         color: ${theme.colors.brand.secondary.main};
         padding: 0 ${theme.spacing.xs};
      }
   `,
);
const Input = styled.input(
   ({ theme }) => css`
      border: 1.2px solid ${theme.neutral.lightest};
      margin: ${theme.spacing.xs} 0 ${theme.spacing.md};
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border-radius: ${theme.spacing.xs};
      display: block;
      width: 55%;

      line-height: ${theme.text.text1.lineHeight};
      font-family: ${theme.text.text1.family};
      font-size: ${theme.text.text1.size};
      font-weight: ${theme.text.text1.weight};

      :focus {
         outline-color: ${theme.colors.brand.secondary.main};
      }
   `,
);

export default Utils;
